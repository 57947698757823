<template>
  <div
    data-cy="page-mentor-eval"
    aria-labelledby="mentor evaluation page"
    ref="mentorEvaluationPage"
  >
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>
    <div v-else-if="show_success" data-cy="page-mentor-eval-success" aria-labelledby="mentor evaluation success page" ref="mentorEvaluationSuccessPage">
      <div class="flex flex-col justify-center items-center box-style pb-20">
        <div
            class="text-3xl text-dark-purple font-bold pt-12 pb-4"
            data-cy="add-success-header"
        >
          Success!
        </div>
        <div class="text-xl">
          Your end-of-year survey has been successfully sent.
        </div>
        <div class="pb-8 text-xl">
          Thanks for sharing feeback about your mentor with us.
        </div>
      </div>
    </div>
    <div v-else>
      <div class="header-text pb-3" data-cy="header-mentor-eval">
        Year-End mentor evaluation for {{ mentor.prefix }}
        {{ mentor.firstname }}
        {{ mentor.lastname }}
      </div>
      <div class="subhead-text pb-3">
        Tell us about your experience this year with your mentor. Your responses
        are confidential and help us improve the program.
      </div>
      <div class="box-style">
        <div class="pb-6" v-for="field in survey.fields" :key="field.field_id">
          <label class="subhead-text">
            <div class="pb-2">{{ field.name }}</div>
          </label>
          <div v-if="field.type === 'DROPDOWN'">
            <ul class="grid grid-cols-11 gap-2 w-1/2">
              <li class="relative inline-block" v-for="option in field.options" :key="option.field_option_id">
                <input
                    class="sr-only peer"
                    type="radio"
                    name="answer"
                    :value="option.value"
                    :id="`r${option.value}`"
                    v-if="option.value !== ''"
                    v-model="answers[field.field_id]"
                />
                <label
                    v-if="option.value !== ''"
                    class="leading-relaxed block border border-dark-purple rounded-full text-xl text-dark-purple pt-1 text-center w-8 h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                    :for="`r${option.value}`"
                >{{ option.value }}</label
                >
              </li>
            </ul>
          </div>
          <div class="ml-12" v-if="field.type === 'RADIO'">
            <div v-for="option in field.options" :key="option.field_option_id">
              <label class="pb-2 block" for="radio-pair-again-yes">
              <input
                  :id="field.field_id"
                  :name="field.field_id"
                  type="radio"
                  class="mb-2 mr-2"
                  :value="option.value"
                  v-model="answers[field.field_id]"
              />
                {{ option.display_value }}
            </label></div>
          </div>
          <div class="ml-12" v-if="field.type === 'TEXTAREA'">
            <div class="w-2/3">
              <textarea
                  id="textarea-mentor-feedback"
                  class="text-sm p-1 pl-2 border-steel-gray rounded h-24 w-full"
                  v-model="answers[field.field_id]"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="relative h-14">
          <div class="absolute bottom-0 right-0">
            <button
              class="inline-block mr-6 py-3 px-9 bg-white border-purple border rounded-full text-dark-purple hover:text-white hover:bg-purple"
              @click="$router.go(-1)"
            >
              Cancel
            </button>
            <button
              class="inline-block py-3 px-9 bg-purple hover:bg-dark-purple rounded-full text-white"
              @click="saveMentorFeedback"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get, post} from "@/composables/httpUtil";
import { mapGetters } from "vuex";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";

export default {
  name: "MentorEval",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      mentor: {},
      survey: {},
      answers: {},
      isLoading: true,
      show_success: false,
    };
  },
  mounted() {

    this.mentor = this.get_summer_mentors.find((m) => {
      return m.mentor_id === this.$route.params.mentor_id;
    });
    const url = `surveys/start/1f5b6676-ad70-444e-b467-879a86be22bd`;
    get(url).then((response) => {
      this.survey = response;
        this.survey.fields.forEach((f) => {
          this.answers[f.field_id] = ""
        })
      this.isLoading = false;
      this.$refs.mentorEvaluationPage.focus();
    });
  },
  methods: {
    get,
    post,
    saveMentorFeedback: function () {
      let term_id;
      if (this.lookup_next_term.term_id === this.getAcadLevel.term.term_id) {
        term_id = this.lookup_current_term.term_id;
      } else {
        term_id = this.lookup_previous_term.term_id;
      }

      let answers_to_send = []
      for (const key in this.answers) {
        answers_to_send.push({
          field_id: key,
          value: this.answers[key],
        })
      }
      const body = {
        survey_id: "1f5b6676-ad70-444e-b467-879a86be22bd",
        mentor_id: this.mentor.mentor_id,
        student_id: this.student_id,
        term_id: term_id,
        person_id: this.student_id,
        status: "COMPLETE",
        fields: answers_to_send,
      };

      console.log(body)
      this.isLoading = true;

      this.post(
          `surveys/start/1f5b6676-ad70-444e-b467-879a86be22bd`,
          body
        ).then(() => {
          this.isLoading = false;
          this.show_success = true;
        })
    },
  },
  computed: {
    ...mapGetters([
        "get_summer_mentors",
        "student_id",
        "lookup_current_term",
        "lookup_previous_term",
        "lookup_next_term",
        "getAcadLevel"
    ]),
  },
};
</script>

<style scoped></style>
